<template>
  <div class="news__other_infocenter">
    <div class="news__title_wrapper">
      <div style="display: flex; gap: 0.5rem">
        <h2 class="text-uppercase">Новости</h2>
        <swiper-standard-arrows
          class="list-inline-item"
          :swiper="swiperInstance"
        ></swiper-standard-arrows>
      </div>
      <base-button text="Все новости" :url="useInfoLink('новости')"></base-button>
    </div>

    <div class="other_cards_infocenter">
      <swiper
        class="other_cards__swiper"
        :modules="[FreeMode]"
        slides-per-view="auto"
        :space-between="22"
        free-mode
        :breakpoints="{
          768: {
            enabled: true,
          },
          992: {
            enabled: false,
          },
        }"
        @swiper="swiperInstance = $event"
      >
        <swiper-slide v-for="otherCard in otherNews" :key="otherCard.id" class="other_cards__slide">
          <other-news-item :other-card="otherCard"></other-news-item>
        </swiper-slide>
      </swiper>
      <base-button
        class="is-tablet-hide"
        style="margin-top: 15px"
        text="Все новости"
        url="/info/news"
        size="large"
        arrow
        full
      ></base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {type Swiper as SwiperClass} from 'swiper';
import {FreeMode} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {type PropType, ref} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import SwiperStandardArrows from '@/components/UI/swiper-standard-arrows.vue';
import {useInfoLink} from '@/composables/useLink';
import type {INewsOther} from '@/types/store/article';
import OtherNewsItem from './otherNewsItem.vue';

defineProps({
  otherNews: {type: Array as PropType<INewsOther[]>, required: true},
});

const swiperInstance = ref<SwiperClass>();
</script>
